import { NgModule, NgZone } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiLandingPageModule } from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import {
  AppConfigService,
  AuthenticationService, SiAODSModule, SiAppDataService,
  SiemensIdAuthenticationMockService, SiLoginAuthModule, SiLoginInterceptor, SiThemingService, SiUOModule
} from '@building-x/common-ui-ng';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionService } from './services/session.service';
import { OrganizationChangeService } from './services/organization-change.service';
/*import {
  AppConfigService,
  AuthenticationService,
} from 'horizon-ui-ng';
*/
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth.guard';

import { LogoutComponent } from './pages/logout/logout.component';
import { APP_BASE_HREF } from '@angular/common';

// import { SiProfileComponent } from './uo-vertical/si-profile/si-profile/si-profile.component';


const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  { path: 'main', canActivate: [AuthGuard], loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: 'main', pathMatch: 'full' }
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const siemensIdAuthenticationFactory = (
  oAuthService: OAuthService,
  ngZone: NgZone
) => {
  if (environment.test) {
    return new SiemensIdAuthenticationMockService(ngZone);
  } else {
    return new AuthenticationService(
      oAuthService,
      environment.issuer,
      environment.msIssuer,
      environment.profileUrl
    );
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LogoutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SiAODSModule.forRoot({
      apiUrl: `${environment.apiUrl}/me`
    }),
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: true }),
    SiLandingPageModule,
    SiUOModule.forRoot(environment),
    SiLoginAuthModule,
    SiTranslateNgxTModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RouterModule,
    TranslateModule
  ],
  providers: [
    AuthGuard,
    {
      provide: AuthenticationService,
      useFactory: siemensIdAuthenticationFactory,
      // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
      deps: [OAuthService, NgZone]
    },
    { provide: 'siProfileConfig', useValue: environment },
    {
      provide: APP_BASE_HREF,
      useValue: (window as any)['base-href'] === 'ms' ? (window as any)['base-href'] : '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiLoginInterceptor,
      multi: true
    },
    OrganizationChangeService,
    SiAppDataService,
    AppConfigService, //Neu
    SiThemingService //Neu
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(sessionService: SessionService) {
    sessionService.initialize();
  }
}
