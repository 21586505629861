import { Inject, Injectable } from '@angular/core';
import { Observable, from as observableFrom, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService, SiProfileService } from '@building-x/common-ui-ng';
import { filter, map } from 'rxjs/operators';
import { NavbarItem } from '@simpl/element-ng';

export interface AccountInfo {
  title?: string;
  image?: string;
}
export let appList: NavbarItem[] = [];

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  public profileUrl = 'main/profile';
  public standardPlan = 'main/standard-plan';
  protected initialized$ = new ReplaySubject<boolean>(1);
  private readonly whiteListURLs = ['landing', 'logout'];

  constructor(
    @Inject(AuthenticationService) private readonly authService: AuthenticationService,
    private readonly router: Router, @Inject(SiProfileService) private readonly profileService: SiProfileService) { }

  initialize(): void {
    const redirectUrl = document.head.getElementsByTagName('base')[0].href;
    observableFrom(
      this.authService.init(
        environment.clientId,
        environment.issuer,
        redirectUrl,
        redirectUrl,
        environment.audience,
        environment.siemensIdBaseUrl
      )
    ).subscribe(() => {
      this.setURLParams();
      if (this.authService.isAuthenticated()) {
        this.assertValidIdentity();
      } else if (window.location.href.toString().includes('/sso')) {
        this.handleSSO();
      } else {
        this.initialized$.next(false);
      }
    });
  }

  private setURLParams() {
    let param = window.location.hash;
    if (param.length > 1 && !this.whiteListURLs.find(ele => param === `#/${ele}`)) {
      if (param.includes('#/sso/myprofile')) {
        param = this.profileUrl;
      } else if (param.includes('#/sso/standard-plan')) {
        param = this.standardPlan;
      } else if (param.includes('#/sso')) {
        param = param.replace('#/sso', '');
      } else if (param.startsWith('#/')) {
        param = param.substring(2);
      }
      sessionStorage.setItem('entryPointUrl', param);
    }
  }

  private handleSSO() {
    this.authService.loginSSO().subscribe(res => {
      if (res) {
        // logic to sign in user
        this.initialized.pipe(
          filter(signedIn => !!signedIn)).subscribe();
        this.initialized$.next(true);
        this.navigateToEntryString();
      }
    });
  }

  private assertValidIdentity() {
    if (this.hasValidIdentity()) {
      this.initialized$.next(true);
      this.navigateToEntryString();
      return;
    }

    this.profileService.setIdentity().subscribe(() => {
      sessionStorage.setItem('identitySet', 'true');
      this.initialized$.next(true);
      this.navigateToEntryString();
    });
  }

  get initialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }

  hasValidIdentity() {
    return sessionStorage.getItem('identitySet') === 'true';
  }

  private navigateToEntryString() {
    const redirectUrl = sessionStorage.getItem('entryPointUrl');
    if (redirectUrl) {
      sessionStorage.removeItem('entryPointUrl');
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  login(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signIn())
    );
  }

  logout(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signOut())
    );
  }

  requireLogin(): Observable<true> {
    return this.initialized.pipe(
      map<boolean, true>(signedIn => {
        if (!signedIn) {
          throw new Error('User not authenticated');
        }
        return signedIn;
      })
    );
  }

  get authToken(): Observable<string> {
    return this.requireLogin().pipe(
      map(() => this.authService.accessToken)
    );
  }

  get accountInfo(): Observable<AccountInfo> {
    return this.requireLogin().pipe(
      map(() => ({
        title: this.authService.identityClaims?.name,
        image: this.authService.identityClaims?.picture
      }))
    );
  }

  setAppList(apps: any) {
    appList = apps;
  }
}
