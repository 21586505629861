import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService, SiLoginInterceptor } from '@building-x/common-ui-ng';
import { environment } from 'src/environments/environment';


@Component({
  templateUrl: './landing.component.html',
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiLoginInterceptor,
      multi: true
    }]
})
export class LandingComponent implements OnInit {
  appName = environment.product;

  constructor(private readonly authService: AuthenticationService) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.authService.signIn();
    }
  }

}
