<si-login-page [heading]="'APP.NAME' | translate" [subtitle]="'APP.CLAIM' | translate" [links]="[
'HORIZON_UI.ABOUT_PAGE.LINKS_ABOUT.CORPORATE_LINK',
'HORIZON_UI.ABOUT_PAGE.LINKS_ABOUT.PRIVACY_NOTICE',
'HORIZON_UI.ABOUT_PAGE.LINKS_ABOUT.ACCEPTABLE_USE_POLICY'
]" [availableLanguages]="['en', 'de', 'fr']">
  <!-- <button type="button" data-cy="login-auth-login-button" class="btn btn-primary" style="width: 100%;"
    routerLink="/main"> -->
  <!-- {{ 'APP.LOGIN' | translate }} -->
  <!-- </button> -->
</si-login-page>


<!-- This is the HTML from Login Component -->
<!-- <si-login-page data-cy="login-page" [heading]="'APP.NAME'" [warningText]="'HORIZON_UI.LOGIN.WARNING_TEXT' | translate"
  [subtitle]="'APP.CLAIM' | translate" [availableLanguages]="['en', 'de']">
</si-login-page> -->
