import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  Membership, OneTrustCookieTypes, Organization, SiAppDataService, SiCookiePolicyService,
  SiCookiesService, Subscription
} from '@building-x/common-ui-ng';

@Injectable()
export class OrganizationChangeService {
  public org = '';
  public orgChanged = false;
  public organization: Organization = {} as Organization;
  private readonly currentSelectedOrganization = new BehaviorSubject(this.org);
  public currentOrganization = this.currentSelectedOrganization.asObservable();
  private readonly isOrganizationSwitch = new BehaviorSubject(this.orgChanged);
  public isOrgLoaded = this.isOrganizationSwitch.asObservable();
  public readonly reloadNavbar = new BehaviorSubject(false);
  public refreshNavbar = this.reloadNavbar.asObservable();
  public readonly changeOrgName = new BehaviorSubject(this.organization);
  public isOrganizationNameChanged = this.changeOrgName.asObservable();
  public organizationList: Organization[] = [];
  public membershipsList: Membership[] = [];
  public organizationSubscription: Subscription[] = [];
  isSubscriptionLoaded: boolean | undefined;
  organizationSubscriptionChange: Subject<boolean> = new Subject<boolean>();

  constructor(public cookieService: SiCookiesService, private readonly cookiePolicyService: SiCookiePolicyService,
    private readonly siAppDataService: SiAppDataService) { }

  changeOrganization(orgSelected: string) {
    if (orgSelected && this.cookiePolicyService.permitted(OneTrustCookieTypes.Required)) {
      const cookieExpiry = new Date();
      cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
      this.cookieService.setCookie('organizationId', orgSelected, '', cookieExpiry.toUTCString());
    }
    this.reloadNavbar.next(true);
  }

  // required to reload navbar when expired organization is renewed
  refreshOrgData() {
    this.reloadNavbar.next(true);
  }

  orgLoaded(orgLoaded: boolean) {
    this.isOrganizationSwitch.next(orgLoaded);
  }

  getOrganizationName(id: string) {
    return this.siAppDataService.userAllCustomer.find(organization => organization.id === id);
  }
}
