export const environment = {
  production: true,
  product: 'Point UI',
  test: false,
  audience: 'https://horizonint.cloud',
  siemensIdBaseUrl: 'https://login.siemens.com',
  profileUrl: 'https://uss.login.siemens.com/userprofile',
  apiUrl: 'https://prodint.user.horizonint.cloud/api/v1',
  apiUrlV2: 'https://um.user.horizonint.cloud/api/v2',
  userApiUrl: 'https://prodint.um.user.horizonint.cloud/api/v2',
  deviceApiUrl: 'https://product-int-dm.device.horizonint.cloud/api/v2',
  pointApiUrlV2: 'https://product-int.point.horizonint.cloud/api/v2',
  pointApiUrlV3: 'https://product-int.point.horizonint.cloud/api/v3',
  licenseApiUrl: 'https://subscription.horizonint.cloud/int/api',
  clientId: 'UcU4S7fZzkoHlZ1qoWhcYseV8YGCO4fF',
  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token'
};
