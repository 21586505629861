import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';
// import { AuthenticationService } from '@building-x/common-ui-ng';
@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(private readonly sessionService: SessionService, private readonly router: Router) { }

  ngOnInit(): void {
    this.sessionService.initialized.subscribe(signedIn => {
      if (signedIn) {
        sessionStorage.removeItem('identitySet');
        this.sessionService.logout().subscribe();
      } else {
        this.router.navigate(['/']);
      }
    });

  }
}
